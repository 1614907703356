// This file includes polyfills needed by Angular and is loaded before the app.
// You can add your own extra polyfills to this file.
import "core-js/es/symbol";
import "core-js/es/object";
import "core-js/es/function";
import "core-js/es/parse-int";
import "core-js/es/parse-float";
import "core-js/es/number";
import "core-js/es/math";
import "core-js/es/string";
import "core-js/es/date";
import "core-js/es/array";
import "core-js/es/regexp";
import "core-js/es/map";
import "core-js/es/set";
import "core-js/es/reflect";
import "core-js/es/reflect";
import "zone.js/dist/zone";

// If you need to support the browsers/features below, uncomment the import
// and run `npm install import-name-here';
// Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html

// Needed for: IE9
// import 'classlist.js';

// Animations
// Needed for: All but Chrome and Firefox, Not supported in IE9
// import 'web-animations-js';

// Date, currency, decimal and percent pipes
// Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
// import 'intl';

// NgClass on SVG elements
// Needed for: IE10, IE11
// import 'classlist.js';
